import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TypeButton, Icon, SelectComponent } from '@maxi-innovation/ui-kit-frontend';

import { IQuestions, IOptions, ICondition } from '../Form/types';
import {
	updateConditionTypeAction,
	updateQuestionConditionIdAction,
	updateQuestionConditionValueAction,
} from 'action/survey';

interface IProps {
	itemNumber: number;
	questions: IQuestions[];
	deleteCondition: (itemNumber: number) => void;
	questionSchema: IQuestions;
	id: number | undefined;
	currentCondition: ICondition;
}

const questionCondition: IOptions[] = [
	{
		label: 'Равно',
		value: 'E',
	},
	{
		label: 'Больше или равно',
		value: 'GE',
	},
	{
		label: 'Меньше или равно',
		value: 'LE',
	},
];

export const Condition: FC<IProps> = ({
	itemNumber,
	questions,
	deleteCondition,
	questionSchema,
	id,
	currentCondition,
}) => {
	const dispatch = useDispatch();
	const [questionNames, setQuestionNames] = useState<IOptions[]>([]);
	const [questionNameSelect, setQuestionNameSelect] = useState<number>();
	const [defaultName, setDefaultName] = useState<number>();

	const [questionValues, setQuestionValues] = useState<IOptions[]>([]);
	const [defaultValue, setDefaultValue] = useState<string>();
	const [valueKey, setValueKey] = useState(0);

	const [conditionType, setConditionType] = useState<string>();
	const [conditionTypeDefault, setConditionTypeDefault] = useState<string>();

	useEffect(() => {
		if (currentCondition.id) {
			setDefaultName(currentCondition.id);
			setQuestionNameSelect(currentCondition.questionNumber);

			setDefaultValue(currentCondition.value === 'Другое' ? 'OTHER' : currentCondition.value);

			setConditionType(currentCondition.questionType);
			setConditionTypeDefault(currentCondition.type);
		}
	}, [questionSchema, currentCondition]);

	useEffect(() => {
		const tempArr: IOptions[] = [];

		questions.forEach(item => {
			if (item.questionNumber < questionSchema.questionNumber && item.type !== 'TEXT') {
				tempArr.push({
					label: item.title,
					value: item.id,
					data: {
						type: item.type,
						questionNumber: item.questionNumber,
					},
				});
			}
		});

		setQuestionNames(tempArr);
	}, [questions, questionSchema]);

	useEffect(() => {
		const tempArr: IOptions[] = [];

		questions.forEach(item => {
			if (
				conditionType &&
				questionNameSelect &&
				+item.questionNumber === +questionNameSelect
			) {
				if (conditionType === 'SCALE') {
					if (item.max) {
						for (let index = 1; index <= item.max; index++) {
							tempArr.push({
								label: index,
								value: index.toString(),
							});
						}
					} else {
						item.possibleAnswers?.forEach(item => {
							tempArr.push({ label: item.text, value: item.text });
						});
					}
				} else {
					if (item.possibleAnswers) {
						item.possibleAnswers.forEach(answer => {
							tempArr.push({
								label: answer.text,
								value: answer.text,
							});
						});
						if (item.withOwnAnswer) {
							tempArr.push({
								label: 'Другое',
								value: 'OTHER',
							});
						}
					}
				}
			}
		});

		setQuestionValues(tempArr);
	}, [questions, questionNameSelect, conditionType]);

	useEffect(() => {
		if (!id) return;
		let checkQuestions = false;

		questions.forEach(item => {
			if (item.id === id && item.questionNumber < questionSchema.questionNumber)
				checkQuestions = true;
		});
		if (!checkQuestions) {
			deleteCondition(itemNumber);
		}
	}, [questions, id, questionSchema, deleteCondition, itemNumber]);

	const changeNameHandle = (value: string | number, label: string | number, data: unknown) => {
		const {
			data: { questionNumber, type },
		} = questionNames.find(item => item.value === value) as {
			value: string | number;
			label: string | number;
			data: {
				type: string;
				questionNumber: number;
				questionType: string;
			};
		};

		setQuestionNameSelect(questionNumber);

		setConditionTypeDefault('E');
		setConditionType(type);

		setDefaultValue(undefined);
		setValueKey(prev => prev + 1);

		dispatch(
			updateQuestionConditionIdAction(questionSchema.questionNumber, {
				index: itemNumber,
				id: +value,
				type: 'E',
				questionNumber: questionNumber,
				questionType: type,
			})
		);
		dispatch(
			updateQuestionConditionValueAction(questionSchema.questionNumber, itemNumber, undefined)
		);
	};

	const changeValueHandle = (value: string | number) => {
		dispatch(
			updateQuestionConditionValueAction(questionSchema.questionNumber, itemNumber, value)
		);
	};

	const changeConditionHandle = (value: string | number) => {
		dispatch(updateConditionTypeAction(questionSchema.questionNumber, itemNumber, value));
	};

	return (
		<div className="survey-condition__item">
			<div className="survey-condition__field">
				<p className="survey-condition__label">Если в вопросе</p>
				<SelectComponent
					error={!currentCondition.questionNumber}
					placeholder="Выберите вопрос"
					types={questionNames}
					onChange={changeNameHandle}
					defaultValue={questionNames.find(item => item.value === defaultName)}
				/>
			</div>

			{conditionType === 'SCALE' && !!conditionTypeDefault && (
				<>
					<div className="survey-condition__field">
						<p className="survey-condition__label">Выбрано</p>
						<SelectComponent
							error={!currentCondition.type}
							placeholder="Выберите условие"
							types={questionCondition}
							onChange={changeConditionHandle}
							defaultValue={questionCondition.find(
								item => item.value === conditionTypeDefault
							)}
						/>
					</div>
				</>
			)}

			<div className="survey-condition__field">
				<p className="survey-condition__label">Выбрано</p>
				<SelectComponent
					error={!currentCondition.value}
					placeholder="Выберите ответ"
					types={questionValues}
					onChange={changeValueHandle}
					defaultValue={questionValues.find(item => item.value === defaultValue)}
					key={valueKey}
				/>
			</div>

			<Button
				variant={TypeButton.LINKCAPS}
				className="delete-condition"
				onClick={() => deleteCondition(itemNumber)}
			>
				<Icon iconName="Trash" />
				Удалить
			</Button>
		</div>
	);
};
