import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FormInputBlock } from 'core/components';
import { Label } from 'shared/ui/Label';
import { IQuestionComponentProps, IOptions } from '../types';
import { SurveyTextField } from '../fields';
import {
	updateQuestionMaxAction,
	updateQuestionMinAction,
	updateQuestionScaleAction,
	updateQuestionTitleAction,
} from 'action/survey';
import { SelectComponent } from '@maxi-innovation/ui-kit-frontend';

const options: IOptions[] = [
	{ label: 4, value: 4 },
	{ label: 5, value: 5 },
	{ label: 6, value: 6 },
	{ label: 7, value: 7 },
	{ label: 8, value: 8 },
	{ label: 9, value: 9 },
	{ label: 10, value: 10 },
];

export const ScaleQuestion: FC<IQuestionComponentProps> = ({ questionSchema, questionIndex }) => {
	const dispatch = useDispatch();

	const changeHandle = (value: string | number) => {
		questionSchema.max = +value;
		dispatch(updateQuestionScaleAction(questionSchema));
	};

	const onChangeTitleHandler = useCallback(
		(value: string) => {
			questionSchema.title = value;
			dispatch(updateQuestionTitleAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	const onChangeMinHandler = useCallback(
		(value: string) => {
			questionSchema.minLabel = value;
			dispatch(updateQuestionMinAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	const onChangeMaxHandler = useCallback(
		(value: string) => {
			questionSchema.maxLabel = value;
			dispatch(updateQuestionMaxAction(questionSchema));
		},
		[dispatch, questionSchema]
	);

	return (
		<div className="survey-question-body">
			<SurveyTextField
				error={!questionSchema.title}
				name={`questions[${questionIndex}].text`}
				label="Вопрос"
				placeholder="Введите текст вопроса"
				onChange={onChangeTitleHandler}
				maxLength={1000}
				defaultValue={questionSchema.title}
			/>

			<FormInputBlock>
				<Label>Шкала от 1 до</Label>
				<SelectComponent
					error={!questionSchema.max}
					name={`questions[${questionIndex}].max`}
					placeholder=""
					types={options}
					onChange={changeHandle}
					defaultValue={options.find(item => item.value === questionSchema.max)}
				/>
			</FormInputBlock>

			<SurveyTextField
				name={`questions[${questionIndex}].minLabel`}
				label="Минимальное значение"
				placeholder="Добавьте описание (необязательно)"
				onChange={onChangeMinHandler}
				maxLength={255}
				defaultValue={questionSchema.minLabel}
			/>

			<SurveyTextField
				name={`questions[${questionIndex}].maxLabel`}
				label="Максимальное значение"
				placeholder="Добавьте описание (необязательно)"
				onChange={onChangeMaxHandler}
				maxLength={255}
				defaultValue={questionSchema.maxLabel}
			/>
		</div>
	);
};
