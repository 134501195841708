import React from 'react';
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';
import { clearStoreSearchResult, textColor } from '../../utils';
import { floor } from 'entities/office-map/types';

const clickShortFaqItemHandler = (closeHandle: () => void) => {
	if (closeHandle) closeHandle();
	ReactGA.event({
		category: 'Переходы по результатам поиска',
		action: 'Переход в "Карта офиса"',
		label: 'Открытия детального просмотра "Карта офиса"',
	});

	clearStoreSearchResult();
};

interface IProps {
	floor: floor;
	number: string;
	description: string;
	closeHandle: () => void;
}

export const OfficeMapItem = ({ number, description, floor, closeHandle }: IProps) => {
	return (
		<Link
			className="short-faq-item"
			to={`/services/office-map?room=${number}&floor=${floor}`}
			onClick={() => clickShortFaqItemHandler(closeHandle)}
		>
			{textColor(`${number}, ${description}`)}
		</Link>
	);
};
